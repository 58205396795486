
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { LoginInfo } from "@/store";
import { Options, Vue } from "vue-class-component";
import QrcodeVue from "qrcode.vue";
import { State } from "@/store/helper";

@Options({
  emits: ["print", "messageCopy"],
  components: {
    MButton,
    MIcon,
    QrcodeVue
  },
  props: {
    clipboardStatusType: String,
    clipboardMessage: String,
    startLearningLink: String,
    isStartLearningBtnShown: Boolean
  }
})
export default class PrintInvitation extends Vue {
  @State("selectedLoginInfos") loginInfos!: LoginInfo[];
  clipboardStatusType = "";
  clipboardMessage = "";
  startLearningLink = "";
  isStartLearningBtnShown = true;

  print() {
    this.$emit("print");
  }

  messageCopy() {
    this.$emit("messageCopy");
  }
}
